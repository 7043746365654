<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <!--<div>
      <horizontal />
    </div>-->
    <!--menu-->
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <h1>
            <b-icon icon="person-check" /> Gestión de usuarios ({{ rows }})
          </h1>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <div>
              <b-row>
                <b-col
                  ><b-form-select
                    @change="filtro"
                    v-model="filtroSelect"
                    :options="[
                      { value: 'AU', text: 'Todos los usuarios' },
                      { value: 'NE', text: 'Usuarios no matriculados' },
                    ]"
                /></b-col>
                <b-col class="mb-3">
                  <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-input-group-prepend is-text>
                      <b-icon icon="search"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="keyword"
                      placeholder="Buscar"
                      type="text"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
              <b-table
                striped
                responsive
                stacked="lg"
                :items="items"
                :fields="fields"
                id="my-table"
                :per-page="perPage"
                :current-page="currentPage"
                :keyword="keyword"
              >
                <template #cell(rols)="row">
                  <div>
                    <b-icon-person-lines-fill
                      type="button"
                      size="sm"
                      class="mx-1"
                      @click="toRols(row.item.id)"
                      v-b-popover.hover.top="'Roles'"
                    ></b-icon-person-lines-fill>
                    <span>{{ row.item.countRoles }}</span>
                  </div>
                </template>
                <template #cell(state)="row">
                  <b-badge pill :variant="returnColor(row.item.state)">{{
                    returnData(row.item.state)
                  }}</b-badge>
                </template>
                <template #cell(actions)="row">
                  <!--<b-icon-pencil-square
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="dateItem(row.item)"
                  ></b-icon-pencil-square>-->

                  <b-icon-trash
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="modalDelete(row.item)"
                  ></b-icon-trash>
                </template>
              </b-table>
              <b-pagination
                style="display: flex; justify-content: center"
                v-model="currentPage"
                pills
                :total-rows="items.length"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal scrollable centered v-model="show" title="Usuario">
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <b-alert
              :show="dismissCountDown"
              variant="danger"
              @dismiss-count-down="countDownChanged"
            >
              <strong>Error. {{ error }},</strong> ocultando en
              {{ dismissCountDown }} segundos...
            </b-alert>
            <div class="form-group">
              <label for="exampleInputEmail1">Nombre</label>
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Ingrese su nombre"
                v-model="data.name"
                required
              />
            </div>
            <div class="form-group my-3">
              <label for="exampleInputPassword1">Apellido Paterno</label>
              <input
                type="text"
                class="form-control"
                v-model="data.lastnameFather"
                placeholder="Ingrese su apellido"
                required
              />
            </div>
            <div class="form-group my-3">
              <label for="exampleInputPassword1">Apellido Materno</label>
              <input
                type="text"
                class="form-control"
                v-model="data.lastnameMother"
                placeholder="Ingrese su apellido"
                required
              />
            </div>
            <div class="form-group my-1">
              <label for="exampleInputPassword1">Fecha nacimiento</label>
              <input
                type="date"
                class="form-control"
                v-model="data.dateBirth"
                placeholder="Ingrese fecha nacimiento"
                required
              />
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveEditItem(data)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--Mensaje alerta eliminacion-->

    <div>
      <b-modal centered v-model="showDelete" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">
            ¿Seguro que desea eliminar a <b>{{ data.username }}</b
            >?
          </h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="deleteItem(dataDelete)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitDelete()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { BIcon, BIconArrowUp, BIconArrowDown } from "bootstrap-vue";
export default {
  components: { BIcon, BIconArrowUp, BIconArrowDown },
  data: () => ({
    filtroSelect: "AU",
    selected: "",
    keyword: "",
    perPage: 10,
    currentPage: 1,
    dataDelete: "",
    error: "",
    dismissSecs: 2,
    dismissCountDown: 0,
    showDismissibleAlert: false,
    show: false,
    showDelete: false,
    data: {
      id: -1,
      username: "",
      name: "",
      lastnameFather: "",
      lastnameMother: "",
      countRoles: null,
      email: "",
      dni: "",
      dateBirth: "",
      state: "",
    },

    estados: [
      { value: false, name: "Activo" },
      { value: true, name: "Inactivo" },
    ],
    rulesCampo: [(v) => !!v || "Campo requerido"],
    page: {
      title: "Categorias",
    },
    breadcrumbs: [
      {
        text: "Categorías",
        disabled: false,
        to: "#",
      },
      {
        text: "Todos las Categorías",
        disabled: true,
      },
    ],
    fields: [
      { key: "username", label: "Usuario" },
      { key: "name", label: "Nombres" },
      { key: "lastnameFather", label: "Apellido paterno" },
      { key: "lastnameMother", label: "Apellido materno" },
      { key: "rols", label: "Roles" },
      { key: "state", label: "Estado" },
      { key: "actions", label: "Acciones" },
    ],

    dialog: false,
    search: "",
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      name: "",
      props: {
        deleted: Boolean,
      },
      timestamp: "",
    },
    submitted: false,
    defaultItem: {
      id: null,
      name: "",
      props: {
        deleted: Boolean,
      },
      timestamp: "",
    },
  }),

  created() {
    this.initialize();
  },
  computed: {
    rows() {
      return this.desserts.length;
    },
    items() {
      this.currentPage = 1;
      return this.keyword
        ? this.desserts.filter(
            (item) =>
              item.username
                .toLowerCase()
                .includes(this.keyword.toLowerCase()) ||
              item.name.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.lastnameFather
                .toLowerCase()
                .includes(this.keyword.toLowerCase()) ||
              item.lastnameMother
                .toLowerCase()
                .includes(this.keyword.toLowerCase())
          )
        : this.desserts;
    },
  },

  methods: {
    async filtro() {
      this.selected = "circular";
      await this.$axios
        .get("/person?filter=" + this.filtroSelect, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var aux = [];
          for (var i in res.data) {
            var obj = {
              username: res.data[i].username,
              email: res.data[i].email,
              dni: res.data[i].dni,
              dateBirth: res.data[i].dateBirth,
              state: res.data[i].state,
              name: res.data[i].name,
              lastnameFather: res.data[i].lastnameFather,
              lastnameMother: res.data[i].lastnameMother,
              countRoles: res.data[i].countRoles,
              id: res.data[i].id,
            };
            aux.push(obj);
          }
          this.desserts = aux;
          this.selected = "";
        })
        .catch((error) => {
          //Deberia tener el mensaje de error
          this.$errorQuery(error);
        });
    },
    returnData(data) {
      if (data === "A") return "Activo";
    },
    returnColor(data) {
      if (data === "A") return "success";
    },
    toRols(data) {
      this.$router.push("/users/data/" + data);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(data) {
      this.error = data;
      this.dismissCountDown = this.dismissSecs;
    },
    exit() {
      this.show = false;
    },
    exitDelete() {
      this.showDelete = false;
      this.data.username = "";
    },
    modalDelete(data) {
      this.showDelete = true;
      this.dataDelete = data;
      this.data.username = data.username;
    },
    login() {
      this.$router.push("/");
    },
    register() {
      this.$router.push("register");
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/person", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var aux = [];
          for (var i in res.data) {
            var obj = {
              username: res.data[i].username,
              email: res.data[i].email,
              dni: res.data[i].dni,
              dateBirth: res.data[i].dateBirth,
              state: res.data[i].state,
              name: res.data[i].name,
              lastnameFather: res.data[i].lastnameFather,
              lastnameMother: res.data[i].lastnameMother,
              countRoles: res.data[i].countRoles,
              id: res.data[i].id,
            };
            aux.push(obj);
          }
          this.desserts = aux;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async dateItem(item) {
      this.show = true;
      let res = await this.$axios.get("/person/edit?id=" + item.id, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      });
      this.data.id = res.data.id;
      this.data.username = res.data.username;
      this.data.name = res.data.name;
      this.data.lastnameFather = res.data.lastnameFather;
      this.data.lastnameMother = res.data.lastnameMother;
      this.data.email = res.data.email;
      this.data.dni = res.data.dni;
      this.data.dateBirth = res.data.dateBirth;
      this.data.state = res.data.state;
    },
    async saveEditItem(item) {
      if (item.id != -1) {
        try {
          await this.$axios
            .post("/person/edit", this.data, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.show = false;
              this.initialize();
            })
            .catch((e) => {
              this.showAlert(e.response.data.content);
            });
        } catch (e) {
          console.log(e);
        }
      }
    },
    async deleteItem(item) {
      this.showDelete = false;
      if (item != "") {
        try {
          await this.$axios
            .get("/person/delete?id=" + item.id, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.initialize();
            });
        } catch (e) {
          this.$message.error("Error!");
          console.log(e);
        }
      }
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>
